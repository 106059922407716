import Banner from "./banner/Banner";
import "./banner/Banner.css";
import CounterSection from "./CounterSection/CounterSection";
import LatestTechnology from "./LatestTechnology/LatestTechnology";
import LogoSlider from "./LogoSlider/LogoSlider";
import MeetSection from "./MeetSection/MeetSection";
import Manufacturing_Unit from "./Manufacturing_Unit/Manufacturing_Unit";
import OurProfessionalism from "./OurProfessionalism/OurProfessionalism";
import WeAreHome from "./WeAreHome/WeAreHome";
import WhatOurClient from "./WhatOurClient/WhatOurClient";
import WhyShould from "./WhyShould/WhyShould";
import Certificates from "./Certificates/Certificates";

function Home() {
  return (
    <>
      <Banner />
      <LatestTechnology />
      <WeAreHome />
      {/* <LogoSlider /> */}
      <WhyShould />
      <OurProfessionalism />
      <CounterSection />
      <Manufacturing_Unit />
      <MeetSection />
      <Certificates />
      {/* <WhatOurClient /> */}
    </>
  );
}

export default Home;
