import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ExperienceSection.css";
import CountUp from "react-countup";
const ExperienceSection = () => {
  return (
    <>
      <section className="experince-section">
        <Container>
          <div className="text-holderr text-center">
            <h3>
              At KAUMODAKI Mfg., PUNE, we extend our commitment to saving our
              clients' time and money through our dedicated export services. We
              specialize in providing professional technical solutions alongside
              top-notch services and materials, never compromising on quality.
              Our team is renowned for its high efficiency & qualifications,
              coupled with a professional approach that ensures swift resolution
              of any technical issues that may arise during the export process.
              Whether it's navigating regulatory requirements, optimizing
              logistics, or ensuring product integrity, we stand ready to
              deliver comprehensive export solutions that meet and exceed
              expectations.{" "}
            </h3>
            <h3 className="mt-3">
              {" "}
              Partner with KAUMODAKI Mfg., PUNE for your export needs and
              experience excellence in service, reliability in materials, and
              peace of mind in quality assurance. We are here to facilitate your
              global reach efficiently and effectively.
            </h3>
          </div>
          <div className="overlay-color"></div>
          <Row className="counter-row">
            <Col lg={2} md={4} sm={6} xs={6}>
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/experiencecertificate.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <h4>
                    <CountUp start={0} end={15} duration={4} decimals={0} />
                  </h4>
                  <p className="me-2">
                    Years <br />
                    Experience
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={2} md={4} sm={6} xs={6}>
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon/star2.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <h4>
                    <CountUp start={0} end={10} duration={4} decimals={0} />K
                  </h4>
                  <p className="me-2">
                    Happy <br />
                    Clients
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={2} md={4} sm={6} xs={6}>
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/worldwide2.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <h4>
                    <CountUp start={0} end={2597} duration={4} decimals={0} />
                  </h4>
                  <p className="me-2">
                    Clients in <br />
                    Gulf Countires
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={2} md={4} sm={6} xs={6}>
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/worldwide2.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <h4>
                    <CountUp start={0} end={953} duration={4} decimals={0} />
                  </h4>
                  <p className="me-2">
                    Clients in <br />
                    Europe
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={2} md={4} sm={6} xs={6}>
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/team-management2.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <h4>
                    <CountUp start={0} end={135} duration={4} decimals={0} />
                  </h4>
                  <p className="me-2">
                    Quality <br />
                    Staff
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ExperienceSection;
