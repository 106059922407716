import React from 'react'
import './CounterSection.css'
import { Col, Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup'
const CounterSection = () => {
    return (
        <>
            <section className='conter-section'>
                <Container>
                    <Row className='counter-row'>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <div className="d-flex location-adddd">
                                <div className="icon-holder">
                                    <img className="location-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/experience.png"} alt="Logo-1" />
                                </div>
                                <div className="text-holder ps-2">
                                    <h4><CountUp start={0} end={15} duration={4} decimals={0} /></h4>
                                    <p className="me-2">Years <br />Experience</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <div className="d-flex location-adddd">
                                <div className="icon-holder">
                                    <img className="location-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/costumer.png"} alt="Logo-1" />
                                </div>
                                <div className="text-holder ps-2">
                                    <h4><CountUp start={0} end={500} duration={4} decimals={0} /></h4>
                                    <p className="me-2">Happy <br />Clients</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <div className="d-flex location-adddd">
                                <div className="icon-holder">
                                    <img className="location-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/worldwide.png"} alt="Logo-1" />
                                </div>
                                <div className="text-holder ps-2">
                                    <h4><CountUp start={0} end={20} duration={4} decimals={0} /></h4>
                                    <p className="me-2">Clients in <br />Gulf Countires</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <div className="d-flex location-adddd">
                                <div className="icon-holder">
                                    <img className="location-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/worldwide.png"} alt="Logo-1" />
                                </div>
                                <div className="text-holder ps-2">
                                    <h4><CountUp start={0} end={0} duration={4} decimals={0} /></h4>
                                    <p className="me-2">Clients in <br />Europe</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} md={4} sm={6} xs={6}>
                            <div className="d-flex location-adddd">
                                <div className="icon-holder">
                                    <img className="location-icon" src={process.env.PUBLIC_URL + "/assets/images/icon/team-management.png"} alt="Logo-1" />
                                </div>
                                <div className="text-holder ps-2">
                                    <h4><CountUp start={0} end={15} duration={4} decimals={0} /></h4>
                                    <p className="me-2">Quality <br />Staff</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CounterSection
