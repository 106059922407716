import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./MeetSection.css";
import CommanButton from "../../CommanComponent/CommanButton/CommanButton";
import EnquiryModal from "../../CommanComponent/CommanModal/EnquiryModal/EnquiryModal";
import { Link } from "react-router-dom";
const MeetSection = () => {
  const [modalEnquiry, setModalEnquiry] = React.useState(false);

  // Function to handle brochure download
  const handleDownloadBrochure = async () => {
    try {
      // Fetch the brochure file
      const response = await fetch("/assets/Brochure/Brochure.pdf"); // Replace with the actual file path
      if (!response.ok) {
        throw new Error("Failed to fetch the brochure");
      }
      const blob = await response.blob(); // Convert the response to a blob

      // Create a temporary link to simulate a download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Brochure.pdf"; // Specify the file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the temporary link
      window.URL.revokeObjectURL(url); // Release the object URL
    } catch (error) {
      console.error("Error downloading the brochure:", error);
    }
  };

  return (
    <>
      <section className="meet-section">
        <Container>
          <Row>
            <Col md={8}>
              <div className="heading-holder">
                <h2>Meet The </h2>
                <h3>KAUMODAKIs</h3>
                <p>
                  Our experts work seamlessly with our customers' team to
                  support big ambitions, challenge preconceptions and amplify
                  outcomes. <span>Let's advance together.</span>
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-flex">
                {/* Button for downloading the E-Brochure */}
                <div className="button-holder text-md-end text-sm-center text-center">
                  <CommanButton
                    className={"blue-btn"}
                    text={"E-Brochure"}
                    onClick={handleDownloadBrochure}
                  />
                </div>
                <div
                  className="button-holder text-md-end text-sm-center text-center"
                  onClick={() => setModalEnquiry(true)}
                >
                  <CommanButton className={"blue-btn"} text={"Enquire"} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <EnquiryModal show={modalEnquiry} onHide={() => setModalEnquiry(false)} />
    </>
  );
};

export default MeetSection;
