import React from 'react'
import ExportServicesBanner from './ExportServicesBanner/ExportServicesBanner'
import ExportServicesTab from './ExportServicesTab/ExportServicesTab'
import ExperienceSection from './ExportServicesTab/ExperienceSection/ExperienceSection'
import OurServicesProcess from './ExportServicesTab/OurServicesProcess/OurServicesProcess'
import LogoSlider from '../home/LogoSlider/LogoSlider'
import MeetSection from '../home/MeetSection/MeetSection'
import WhatOurClient from '../home/WhatOurClient/WhatOurClient'

const ExportServices = () => {
    return (
        <>
            <div className='ExportServices'>
                <ExportServicesBanner />
                <ExportServicesTab />
                <ExperienceSection />
                <OurServicesProcess />
                <LogoSlider headingName={"Our Global Clients"} />
                <MeetSection />
                {/* <WhatOurClient /> */}
            </div>
        </>
    )
}

export default ExportServices
