import React from "react";
import { Container, Row } from "react-bootstrap";

const GrapUp = () => {
  return (
    <>
      <section className="we-are-leader">
        <Container>
          <Row>
            <div className="heading-holder text-center mb-md-5 mb-sm-3 mb-3">
              <h2>
                Gear Up for{" "}
                <span className="our-text">Quality You Can Trust</span>
              </h2>
              <div className="d-flex justify-content-center">
                <div className="border-line me-2"></div>
                <p>Where Precision Meets Reliability.</p>
                <div className="border-line ms-2"></div>
              </div>
            </div>
            <div className="text-holder mb-3">
              <h3>TECHNICAL SUPPORT:</h3>
              <p>
                Our company name quality control by imported machines &
                measuring instruments are used in our factory by the best
                technical staff and also verified time to time through a
                government recognized testing laboratory. KAUMODAKI Mfg. Pvt.
                Ltd. also offer third-party inspection report on request..
              </p>
            </div>
            <div className="text-holder mb-3">
              <h3>TESTING EQUIPMENT:</h3>
              <p>
                Our company is fully engaged with an electronically operated
                testing instrument such as Calipers, Standard Gauges with Go Not
                Go, Threaded Gauges, Micro Meters, Electronic Digital Verniers &
                many other approved standard equipment are using in our plants
                which we can maintain accuracy as per drawing & its tolerance &
                get desire result.
              </p>
            </div>

            <div className="text-holder mb-3">
              <h3>OTHER INFORMATION:</h3>
              <p>
                We maintain quality as per customer’s drawing while first stage
                manufacturing in machine & second stage complete finished
                product inspect by each separate lot as specify components and
                then final inspection through our qualified engineers and then
                after that dispatch all materials from our factory.
              </p>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default GrapUp;
