import React, { useState } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import "./Certificates.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Certificates = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const CertificatesSlider = [
    {
      imgSrc: "/assets/images/home/Certificates/ISO.png",
    },
    {
      imgSrc: "/assets/images/home/Certificates/MSFE.png",
    },
    {
      imgSrc: "/assets/images/home/Certificates/Importer-Exporter.png",
    },
  ];

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc);
    setShowModal(true);
  };

  return (
    <>
      <section className="our-client-say Certificates">
        <Container>
          <Row>
            <div className="heading-holder text-center mb-5">
              <h2>Certificates</h2>
            </div>

            <Swiper
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 10 },
                486: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                992: { slidesPerView: 2, spaceBetween: 20 },
                1200: { slidesPerView: 2, spaceBetween: 30 },
                1400: { slidesPerView: 2, spaceBetween: 30 },
              }}
              pagination={{ dynamicBullets: true }}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              className="mySwiper Certificates_swiper"
            >
              {CertificatesSlider.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="Certificates-card mb-5"
                    onClick={() => handleImageClick(item.imgSrc)}
                  >
                    <img
                      src={item.imgSrc}
                      className="Certificates_img"
                      alt="Certificates"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="silder-btn">
              <div
                className="back-btn"
                onClick={() => handleMainSliderClick("prev")}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
              </div>
              <div
                className="next-btn"
                onClick={() => handleMainSliderClick("next")}
              >
                <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
              </div>
            </div>
          </Row>
        </Container>

        {/* Modal for Fullscreen Image */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          size="lg"
          className="fullscreen-modal"
        >
          <Modal.Body className="text-center">
            <img
              src={selectedImage}
              alt="Fullscreen Certificate"
              className="img-fluid"
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default Certificates;
